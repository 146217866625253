import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

// A custom theme for this app
const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#4E46AA",
        contrastText: "#fff",
      },
      secondary: {
        light: "#313131",
        main: "#212121",
        dark: "#181818",
        contrastText: "#fff",
      },
      error: {
        main: "#F46363",
      },
      background: {
        default: "#161616",
      },
      text: {
        primary: "#fff",
        secondary: "#414141",
      },
    },
    typography: {},
  })
);

export default theme;
